<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            :to="{ name: 'main' }"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>

        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Forgot Password? 🔒
          </p>
          <p class="mb-2">
            Enter your email and we'll send you instructions to reset your password
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="resetPasswordForm"
            @submit.prevent="handleFormSubmit"
          >
            <p
              v-if="errorText"
              class="error--text font-weight-semibold"
            >
              {{ errorText }}
            </p>

            <p
              v-if="successText"
              class="success--text font-weight-semibold"
            >
              {{ successText }}
            </p>

            <v-text-field
              v-model="email"
              outlined
              :error-messages="errorMessages.email"
              :rules="[validators.required, validators.emailValidator]"
              label="Email"
              placeholder="john@example.com"
              hide-details
              class="mb-4"
            ></v-text-field>

            <v-btn
              block
              color="primary"
              type="submit"
              :disabled="resetSent"
            >
              Send reset link
            </v-btn>
          </v-form>
        </v-card-text>

        <v-card-actions class="d-flex justify-center align-center">
          <router-link
            :to="{name:'auth-login-v1'}"
            class="d-flex align-center text-sm"
          >
            <v-icon
              size="24"
              color="primary"
            >
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>Back to login</span>
          </router-link>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    />

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
import store from '@/store'
import { emailValidator, required } from '@core/utils/validation'
import { mdiChevronLeft } from '@mdi/js'
import themeConfig from '@themeConfig'
import { onUnmounted, ref } from 'vue'
import authuserStoreModule from './apps/authuser/authuserStoreModule'

export default {
  setup() {
    const errorText = ref(null)
    const successText = ref(null)

    const AUTHUSER_APP_STORE_MODULE_NAME = 'app-authuser'

    // Register module
    if (!store.hasModule(AUTHUSER_APP_STORE_MODULE_NAME)) {
      store.registerModule(AUTHUSER_APP_STORE_MODULE_NAME, authuserStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AUTHUSER_APP_STORE_MODULE_NAME)) store.unregisterModule(AUTHUSER_APP_STORE_MODULE_NAME)
    })

    const resetPasswordForm = ref(null)
    const email = ref('')
    const errorMessages = ref([])
    const resetSent = ref(false)

    const handleFormSubmit = async () => {
      errorText.value = null
      const isFormValid = resetPasswordForm.value.validate()

      if (!isFormValid) return

      await store
        .dispatch('app-authuser/passwordReset', {
          email: email.value,
        })
        .then(response => {
          console.log(response)
          resetSent.value = true
          successText.value = `Password reset link sent to ${email.value}`
        })
        .catch(err => {
          errorText.value = 'Oops, Unable to reset password'

          // console.error()
          console.log('error :>> ', err.message)
          errorMessages.value = err.message
        })
    }

    return {
      resetPasswordForm,
      errorText,
      successText,
      resetSent,
      email,
      handleFormSubmit,
      errorMessages,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      validators: {
        required,
        emailValidator,
      },
      icons: {
        mdiChevronLeft,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
